import React from 'react';

import AuthUserContext from '../AuthUserContext';
import { firebase } from '../../firebase';

const withAuthentication = (Component) =>
  class WithAuthentication extends React.Component {
    constructor(props) {
      super();

      this.state = {
        authUser: null,
        userData: null,
      };
    }

    componentDidMount() {
        firebase.auth.onAuthStateChanged(async authUser => {
            // console.log(authUser);
            
            if (authUser != null) {
                // console.log('acesso ao banco');
                const snapshot = await firebase.db.ref('cadastroEscola')
                    .orderByChild('email')
                    .equalTo(authUser.email)
                    .limitToLast(1)
                    .once("value");

                // console.log('lendo dados');

                const val = snapshot.val();

                // console.log(val);

                const key = val && Object.keys(val) && Object.keys(val)[0];

                const keySchool = key.substring(0, key.indexOf("-"));
                const keyClass = key.substring(key.indexOf("-")+1);

                // console.log(` >>>>>> ${keySchool} /// ${keyClass}`);
                
                if (keySchool === "escola17") {
                  const userData = val && val[key];
                  
                  if (userData) {
                    userData.key = keySchool;
                    userData.defaultClass = keyClass;
                    
                    // console.log(userData);

                    this.setState({ authUser, userData });
                  } else {
                    this.setState({ authUser: null, userData: null });
                  }
                } else {
                  this.setState({ authUser: null, userData: null });
                }
                
            } else {
                this.setState({ authUser: null, userData: null });
            }
        });
    }

    render() {
        const { authUser, userData } = this.state;

        return (
          <AuthUserContext.Provider value={{authUser, userData}}>
            <Component />
          </AuthUserContext.Provider>
        );
    }
  }

export default withAuthentication;